.dark-header {
  background-color: #23283e;
  box-shadow: 1px 1px 10px 1px rgba(215, 215, 215, 0.2);
  z-index: 10;
}

.light-header {
  background-color: #fcfcfc;
  box-shadow: 1px 1px 10px 1px rgba(49, 49, 49, 0.2);
  z-index: 10;
}

@media (max-width: 768px) {
  .header {
    height: 6em;
  }
}

#logoOnly {
  transition: transform 0.23s ease-in-out;
}
#logoOnly:hover {
  transform: rotate(-180deg);
}
