@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap');

.app {
  color: var(--color-fg);
  background-color: var(--color-bg);
}

.light {
  --color-bg: #fcfcfc; /* background */
  --color-bg-alt: #fff;
  --color-fg: rgb(60, 60, 60); /* Text color */
  --color-fg-alt: rgb(40, 40, 40);
  --color-primary: #222222; /* underline nav on hover */
  --shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.dark {
  --color-bg: #23283e; /* background */
  --color-bg-alt: #2a2f4c;
  --color-fg: #bdbddd; /* Text color */
  --color-fg-alt: #cdcdff;
  --color-primary: #90a0d9; /* underline nav on hover */
  --shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

main {
  margin: 0 auto;
}

.center {
  z-index: 450;
  display: flex;
  align-items: center;
}

.link {
  color: var(--color-primary);
  padding-bottom: 0.3em;
  position: relative;
}

.link:hover {
  color: var(--color-primary);
}

.link::before {
  content: '';
  display: inline;
  width: 0%;
  height: 0.2em;
  position: absolute;
  bottom: 0;
  background-color: var(--color-primary);
  transition: width 0.2s ease-in;
}

.link:hover::before {
  width: 100%;
  outline: 0;
}

.link:active {
  transform: translateY(-5px);
  transition: 0.1s ease-in-out;
}

.link-nav {
  color: var(--color-fg);
  font-weight: 500;
  margin-top: 5px;
}

.btn {
  padding: 1.8em 2.5em;
  font-weight: 500;
  font-size: 0.9rem;
  transition: transform 0.2s ease-in-out;
}

.btn-outline {
  color: white;
  border: 2px solid white;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.btn-outline:hover {
  color: white;
}

.btn-outline:before {
  content: '';
  position: absolute;
  background-color: var(--color-primary);
  right: 100%;
  bottom: 0;
  left: 0;
  top: 0;
  z-index: -1;
  transition: right 0.2s ease-in-out;
}

.btn-outline:hover:before {
  right: 0;
}

.btn-icon {
  padding: 0;
}

.btn-icon:hover {
  color: var(--color-primary);
}

.btn-icon:active {
  transform: translateY(-5px);
}

.hero-image-height {
  height: 700px;
}

.white-font {
  color: white;
}

.monserrat {
  font-family: 'Montserrat', sans-serif;
}

.desktop-primary-text {
  font-size: 4.5rem;
  font-weight: 700;
}

.mobile-primary-text {
  font-size: 2.5rem;
  font-weight: 700;
}

.desktop-secondary-text {
  font-size: 1.875rem;
}

.mobile-secondary-text {
  font-size: 1.3rem;
}

.image-darkness {
  filter: brightness(75%);
}

.sepia {
  -webkit-filter: sepia(45%);
  filter: sepia(45%);
}

.header-text-size {
  font-size: 3rem;
}

.sub-header-text-size {
  font-size: 2.5rem;
}

.description-text-size {
  font-size: 1.5rem;
}

@media (max-width: 768px) {
  .header-text-size {
    font-size: 1.5rem;
  }
  .sub-header-text-size {
    font-size: 2rem;
  }
  .description-text-size {
    font-size: 1rem;
  }
}

.center-container-padding {
  padding: 9rem 20rem 9rem 20rem;
}

.resume-container-padding {
  padding: 9rem 22rem 9rem 22rem;
}

@media (max-width: 1280px) {
  .center-container-padding {
    padding: 3rem 4rem 3rem 4rem;
  }

  .resume-container-padding {
    padding: 3rem 6rem 3rem 6rem;
  }
}

background-color {
  background-color: #fcfcfc;
}

.fade-in {
  -webkit-animation: fadein 1s;
  -moz-animation: fadein 1s;
  -ms-animation: fadein 1s;
  -o-animation: fadein 1s;
  animation: fadein 1s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.image-transition-wrap:hover .image-transition {
  transform: translateY(-7px);
}

.image-transition {
  transition: all 300ms;
}
.text-limit {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.hiddenImage {
  opacity: 0;
}
.showImage {
  opacity: 1;
  transition: all 0.75s ease-in-out;
  -webkit-transition-delay: 0.75s;
  -moz-transition-delay: 0.75s;
  -o-transition-delay: 0.75s;
  transition-delay: 0.75s;
}
